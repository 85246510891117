/* eslint-disable space-before-blocks */
import React, { useState } from 'react'
import './style/footer_style.css'
import { FaAngleRight } from 'react-icons/fa6'
import logo from '../../assert/banner/triveSpotLogo.png'
import { useNavigate } from 'react-router-dom'
export const Footer: React.FC = () => {
  const [email, setEmail] = useState('')
  const navigate = useNavigate()

  const handleChange = (e: any): any => {
    const { name, value } = e.target
    // eslint-disable-next-line @typescript-eslint/space-before-blocks
    if (name === 'sub-email') { setEmail(value) }
  }
  const submitEmail = (): any => {
    // eslint-disable-next-line @typescript-eslint/space-before-blocks
    if (email !== '') {
      navigate('/join#waitlist', { state: { email } })

      window.location.reload()
    }
  }
  return (
    <div className='container mt-5 foot-font'>
      <div className='yellow-banner row row-cols-lg-2 row-cols-1 align-items-center'>
        <div className='col'>
          <h3 className='yellow-banner__text m-4 d-none d-lg-block'>We are just starting our journey,<br /> We promise that it will be cool!</h3>
          <h3 className='yellow-banner__text--sm m-4 d-block d-lg-none'>Share your thoughts,<br /> engage with others,<br /> and be part of the ongoing dialogue.
          </h3>
        </div>
        <div className='col'>
          <button className=' yellow-banner__button p-3 m-4' onClick={() => { navigate('/join#waitlist') }}>Join Waitlist <FaAngleRight className='icon-size' /></button>
        </div>
      </div>
      <div className='row row-cols-1 row-cols-lg-2 mt-5 '>
        <div className='col'>
          <div className=' d-md-flex d-sm-flex d-lg-block  justify-content-center'>
            <img src={logo} alt='logo' width={172} height={43} />
          </div>
          <div className=' d-md-flex d-sm-flex d-lg-block  justify-content-center'>
            <p className='mt-2'>
              A Product of Genade Global Services Ltd.<br /> Registered in England & Wales (15149519)
            </p>
          </div>
        </div>
        <div className='col '>
          <h5 className='trial-font  d-md-flex d-sm-flex d-lg-block  justify-content-center'>6 free month for our first  <span className='trial-font__triver'>Trivers</span></h5>
          <p className='mt-2  text-lg-start text-sm-center'>
            We’re really honest guys and promise not to send you ads. We will write only once when our platform is ready.
          </p>
          <div className='row row-cols-2  '>
            <div className='col-8 align-self-center'>
              <input type='text' placeholder='Email address' name='sub-email' className='form-control p-3' onChange={(e) => { handleChange(e) }} />
            </div>
            <div className='col-4 d-flex'>
              <button className='waitlist-button p-3 w-100' onClick={submitEmail}>Join Waitlist</button>
            </div>

          </div>
        </div>
      </div>
      <hr />
      <div className='mt-4 mb-4'>
        <div className='text-end'>© 2023 Trivespot. All rights reserved.</div>
      </div>
    </div>
  )
}
