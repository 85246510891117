/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/space-before-blocks */
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FaFaceSmile, FaThumbsUp } from 'react-icons/fa6'
import './style/modal_style.css'

export default function ModalComponent (props: any): any{
  const handleThanks = (): any => {
    window.location.reload()
  }
  return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body className='modal--class'>
               <div className='text-center'>{props.dataRes === 'Email already submitted' ? <FaFaceSmile className='modal-smiley text-center' /> : <FaThumbsUp className='modal-smiley__success text-center'/>}</div>
               <h5 className='pt-2 pb-2 text-center'> {props.dataRes === 'Email already submitted' ? 'Oops' : 'Successful'} </h5>
                <h4 className='text-center'>{props.dataRes}</h4>
                <div className='d-flex justify-content-center'><Button onClick={() => { handleThanks(); props.onHide }} className='btn-secondary'>Thanks</Button></div>
            </Modal.Body>
        </Modal>
  )
}
