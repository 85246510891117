import React from 'react'
import Banner from '../../Components/WaitList/banner/banner'
import { Footer } from '../../../../Global/Layout/footer_layout'

const WaitList: React.FC = () => {
  return (
    <div>
      <Banner />
      <Footer/>
    </div>
  )
}
export default WaitList
