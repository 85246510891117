/* eslint-disable space-before-blocks */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable no-console */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useState } from 'react'
import '../waitlist_style/waitlist_banner_style.css'
import Logo from '../../../../../assert/banner/triveSpotLogo.png'
// import { IoMdInformationCircleOutline } from 'react-icons/io'
// import { FaAngleRight } from 'react-icons/fa6'
import bannerWave from '../../../../../assert/banner/waveVector.png'
import bannerImage from '../../../../../assert/banner/bannerimages.png'
import { Link, useLocation } from 'react-router-dom'
// eslint-disable-next-line import/no-extraneous-dependencies
import FormHook from '../../../../../Hook/formHook'
import FormService from '../../../../../Services/formService'
import ModalComponent from '../../../../../Global/Modal/modalComponent'

const Banner: React.FC = () => {
  const location = useLocation()
  const email = location?.state?.email
  const { handleUpdate, formData, error, handleError } = FormHook(email)
  const [modalShow, setModalShow] = useState(false)
  const [dataRes, setDataRes] = useState('')
  const handleSubmit = async (): Promise<void> => {
    console.log(formData)
    handleError()
    if ((formData?.name !== undefined && formData?.phone_number !== undefined) && (formData?.email !== undefined || email !== undefined)) {
      const data = {
        email: formData.email ? formData.email : email,
        name: formData.name,
        phone_number: formData.phone_number
      }
      await FormService.joinWaitlist(data).then((res) => {
        console.log(res.data)
        const resp = res?.data?.success[0]
        setDataRes(resp)
        setModalShow(true)
        // window.location.reload()
      }).catch((error: any) => {
        console.log(error)
      })
    }
  }
  console.log(formData)
  return (
        <>
            <header className='banner mb-5'>
                <img src={bannerWave} alt='bannerWave' className='banner-wave' />
                <div className='banner-content'>
                    <div className='banner-content__center'>
                        <div className="container">
                            <nav className='row row-cols-lg-2 row-cols-md-1 pt-4'>
                                <div className='col'>
                                    <Link to={'/'}> <img alt='logo' src={Logo} height={35} width={143} /></Link>
                                </div>
                                <div className=' col'>
                                    <div className='d-flex justify-content-end'>
                                        <a href='#waitlist'><button className='banner-content__center--button p-2 ps-4 pe-4'>Join Waitlist</button></a>
                                    </div>
                                </div>
                            </nav>
                            <main className='mt-lg-5 pt-lg-5 row row-cols-lg-2 row-cols-1 mt-5'>
                                {/* left hand info */}
                                <div className='col d-grid  banner-left' id='waitlist'>
                                    <div className='banner-info__bolds pt-2'>
                                        Get notified when we <span className='banner-info'>LAUNCH!</span>
                                    </div>
                                    <div className='banner-info__explained mt-1'>
                                        Fill your details below to enable us notify you when we launch.
                                    </div>
                                    <div className='pe-lg-5'>
                                        <label className='form-label'>
                                            Email Address
                                        </label>
                                        <input type='text' className='form-control p-3' defaultValue={email} name='email' onChange={handleUpdate} pattern='/^[a-zA-Z0-9]+@\.com$/' required />
                                       {formData?.email === undefined && <p className={`${(error?.emailerror === 'Email is empty') ? 'bg-secondary p-1 text-white' : 'bg-white'}`}>{error?.emailerror === 'Email is empty' ? error?.emailerror : ''}</p> }
                                    </div>
                                    <div className='pe-lg-5'>
                                        <label className='form-label'>
                                            Name
                                        </label>
                                        <input type='text' className='form-control p-3' name='name' onChange={handleUpdate} pattern='/^[a-zA-Z0-9]+$/' required />
                                      { formData?.name === undefined && <p className={`${(error?.nameerror === 'Name is empty') ? 'bg-secondary p-1 text-white' : 'bg-white'}`}>{error?.nameerror === 'Name is empty' ? error?.nameerror : ''}</p> }
                                    </div>
                                    <div className='pe-lg-5'>
                                        <label className='form-label'>
                                            Phone Number
                                        </label>
                                        <input type='number' className='form-control p-3' name='phone_number' onChange={handleUpdate} pattern='/^[0-9]+$/' required minLength={10} />
                                        { formData?.phone_number === undefined && <p className={`${(error?.phone_numbererror === 'Phone number is empty') ? 'bg-secondary p-1 text-white' : 'bg-white'}`}>{error?.phone_numbererror === 'Phone number is empty' ? error?.phone_numbererror : ''}</p>}
                                    </div>
                                    <div className='mt-4 pe-lg-5 mb-4 pb-3'>
                                        <button className='banner-content__center--button  pt-lg-3 pt-3 w-100  pb-lg-3 pb-3 ps-3 pe-lg-5 pe-4' onClick={handleSubmit}>Submit Request</button>
                                    </div>
                                </div>

                                {/* right hand images */}
                                <div className='col'>
                                    <img alt='banner-images' src={bannerImage} className='p-lg-5 banner-images' />
                                </div>
                            </main>
                        </div>
                    </div>
                </div>
                <img src={bannerWave} alt='bannerWaveBase' className='banner-wave__base' />
            </header>
            <ModalComponent show={modalShow} onHide={() => { setModalShow(false) }} dataRes = {dataRes} />
        </>
  )
}

export default Banner
