/* eslint-disable space-before-blocks */
// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios'
import { BaseUrl } from '../Base/base_url'

axios.defaults.baseURL = BaseUrl

axios.interceptors.request.use(
  function (config: any) {
    config.headers['Content-Type'] = 'application/json'
    return config
  },
  async (error) => {
    return await Promise.reject(error)
  }
)

const FormService = {
  joinWaitlist: async (data: any) => {
    return await axios.post('/subform', data)
  }
}

export default FormService
