import React from 'react'
import Banner from '../../Components/LandingPage/banner/banner'
import Features from '../../Components/LandingPage/features/features'
import { Footer } from '../../../../Global/Layout/footer_layout'

const Home: React.FC = () => {
  return (
    <div>
      <Banner />
      <Features />
      <Footer/>
    </div>
  )
}
export default Home
