/* eslint-disable space-before-blocks */
import { useState } from 'react'

const FormHook = (email: any): any => {
  const [formData, setFormData] = useState<any>()
  const [error, setError] = useState<any | string>()
  const handleUpdate = (e: any): any => {
    const { name, value } = e.target
    setFormData((prevState: any) => ({ ...prevState, [name]: value }))
  }
  const handleError = (): any => {
    if ((formData?.email === undefined && email === undefined) || (formData?.name === undefined && formData?.name === '') || (formData?.phone_number === undefined && formData?.phone_number === '')) {
      setError((prevState: any) => ({ ...prevState, emailerror: 'Email is empty' }))

      if (formData?.name === undefined || formData?.name === '') {
        setError((prevState: any) => ({ ...prevState, nameerror: 'Name is empty' }))
      }
      if (formData?.phone_number === undefined || formData?.phone_number === '') {
        setError((prevState: any) => ({ ...prevState, phone_numbererror: 'Phone number is empty' }))
      }
    } else if ((formData?.name === undefined || formData?.name === '') || (formData?.phone_number === undefined && formData?.phone_number === '')) {
      setError((prevState: any) => ({ ...prevState, nameerror: 'Name is empty' }))
      if (formData?.phone_number === undefined || formData?.phone_number === '') {
        setError((prevState: any) => ({ ...prevState, phone_numbererror: 'Phone number is empty' }))
      }
    } else if ((formData?.phone_number === undefined && formData?.phone_number === '')) {
      setError((prevState: any) => ({ ...prevState, phone_numbererror: 'Phone number is empty' }))
    }
  }
  return { formData, handleUpdate, error, handleError }
}

export default FormHook
