import React, { Fragment } from 'react'
import '../style/features_style.css'
import { FaAngleRight } from 'react-icons/fa6'
import featureD from '../../../../../Constants/json/features.json'
import baseBanner from '../../../../../assert/banner/baseBanner.png'
import cardCircle from '../../../../../assert/cardIcons/circle.png'
import currency from '../../../../../assert/cardIcons/currency.png'
import truck from '../../../../../assert/cardIcons/bus.png'
import graph from '../../../../../assert/cardIcons/graph.png'
import { useNavigate } from 'react-router-dom'

const Features: React.FC = () => {
  const navigate = useNavigate()
  return (
        <section id="key-features" className='container'>
            <div className='section-title'>
                <p className='p-2'>key features</p>
            </div>
            <div className='row row-cols-lg-2 row-cols-1'>
                <div className='col' >
                    <h4 className='section-h4' >Connect, Learn, Thrive</h4>
                    <p>
                        Trivespot is more than a platform; it&apos;s a movement. Join a community that values authenticity, celebrates growth, and turns setbacks into success stories.
                    </p>
                </div>
                <div className='col'>
                    <div className='mt-lg-3 mt-2 mb-lg-0 mb-5 d-lg-flex d-block justify-content-end'>
                        <button className='section-content__center--button pt-lg-3 pt-3  pb-lg-3 pb-3 ps-3 pe-3' onClick={() => { navigate('/join') }}>Share Your Story <FaAngleRight /></button>
                    </div>
                </div>

            </div>
            <div className='container p-lg-5 features-list'>
                <div className='row row-cols-3 features-list__content'>
                    {
                        featureD.map((details: string | any, index: number) => {
                          const ImagePath: string = details.image
                          return <Fragment key={index + 1}>

                                <div className='col' data-aos='fade-right'>
                                    <img alt='comityIcon' src={`${process.env.PUBLIC_URL}${ImagePath}`} />
                                    <h5 className='section-h5'>{details.title}</h5>
                                    <p>{details.details}</p>
                                </div>

                            </Fragment>
                        })
                    }
                </div>
            </div>
            <div className='pt-3 pb-3'>
                <h3 className='coming-soon text-center'>Coming Soon!</h3>
            </div>
            <div className='mt-3' data-aos='zoom-in-up'>
                <img src={baseBanner} alt='base banner' className='base-banner' />
            </div>
            <div className='row row-cols-lg-2 row-cols-1' aria-label='join-waitlist'>
                <div className='col'>
                    <h4 className='base-banner__info'>Interested in knowing why you should use Trivespot?</h4>
                </div>
                <div className='col d-lg-grid justify-content-end mb-lg-0 mb-3'>
                    <button className='section-content__center--button p-2 ps-4 pe-4 base-banner__height' onClick={() => { navigate('/join') }}>Join Waitlist <FaAngleRight className='icon-size' /></button>
                </div>
            </div>
            <div className='row row-cols-lg-3 row-cols-1' aria-label='card information'>
                <div className='col banner-card' data-aos='fade-right'>

                    <div className='card card-behaviour border-0 bg-light box-shadow'>
                        <div className='card-body '>
                            <div className='d-flex'>
                              <img src={currency} alt='currency icon' width={40} height={40}/>
                              <div className='card-text'>
                                <p className='fw-bold'>
                                🚀 Transformative Community
                                </p>
                                <p> Connect with a community that understands the importance of failures in the journey to success. Share, learn, and grow together.</p>
                              </div>
                            </div>
                        </div>
                    </div>
                    <img src={cardCircle} alt='circle icon' className='circle-icon' />
                </div>
                <div className='col banner-card' data-aos='fade-left'>

                    <div className='card card-behaviour border-0 bg-light box-shadow'>
                        <div className='card-body '>
                            <div className='d-flex'>
                              <img src={truck} alt='currency icon' width={40} height={40}/>
                              <div className='card-text'>
                                <p className='fw-bold'>
                                🌐 Inclusive Secure Networking
                                </p>
                                <p>Build a network that goes beyond surface-level connections. We focuses on fostering relationships that transcend the digital space with high regard for Security of data.</p>
                              </div>
                            </div>
                        </div>
                    </div>
                    <img src={cardCircle} alt='circle icon' className='circle-icon' />
                </div>
                <div className='col banner-card ' data-aos='fade-left'>

                    <div className='card card-behaviour border-0 box-shadow banner-card--bg'>
                        <div className='card-body '>
                            <div className='d-flex'>
                              <img src={graph} alt='currency icon' width={40} height={40}/>
                              <div className='card-text'>
                                <p className='fw-bold'>
                                💼 Personal and Professional Development
                                </p>
                                <p> Access resources, articles, and insights curated to accelerate your growth, both personally and professionally.</p>
                              </div>
                            </div>
                        </div>
                    </div>
                    <img src={cardCircle} alt='circle icon' className='circle-icon' />
                </div>

            </div>
        </section>
  )
}

export default Features
