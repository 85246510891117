import React from 'react'
import '../style/banner_style.css'
import Logo from '../../../../../assert/banner/triveSpotLogo.png'
import { IoMdInformationCircleOutline } from 'react-icons/io'
import { FaAngleRight } from 'react-icons/fa6'
import bannerWave from '../../../../../assert/banner/waveVector.png'
import bannerImage from '../../../../../assert/banner/bannerimages.png'
import { Link, useNavigate } from 'react-router-dom'
const Banner: React.FC = () => {
  const navigate = useNavigate()
  return (
        <>
            <header className='banner mb-5'>
                <img src={bannerWave} alt='bannerWave' className='banner-wave' />
                <div className='banner-content'>
                    <div className='banner-content__center'>
                        <div className="container">
                            <nav className='row row-cols-lg-2 row-cols-md-1 pt-4'>
                                <div className='col'>
                                    <Link to={'/'}><img alt='logo' src={Logo} height={35} width={143} /></Link>
                                </div>
                                <div className=' col'>
                                    <div className='d-flex justify-content-end'>
                                        <a href='#key-features' className='url-style'><h5 className='p-2 d-lg-block d-none'>About <IoMdInformationCircleOutline /></h5></a>
                                        <button className='banner-content__center--button p-2 ps-4 pe-4' onClick={() => { navigate('/join#waitlist') }}>Join Waitlist</button>
                                    </div>
                                </div>
                            </nav>
                            <main className='mt-lg-5 pt-lg-5 row row-cols-lg-2 row-cols-1'>
                                {/* left hand info */}
                                <div className='col d-grid  banner-left'>
                                    <p className='banner-info mt-5 mb-2 fs-6 fw-bold'>🚀 Join the Trivespot Revolution</p>
                                    <div className='banner-info__bold pt-2 pb-2'>
                                        Empowering <span className='banner-info'>Careers</span>, Fostering Growth!
                                    </div>
                                    <div className='banner-info__explained mt-3'>
                                        At Trivespot, we believe that success is a journey best taken together. Whether you&apos;re a seasoned professional eager to share your wisdom or someone navigating the challenges of career growth, Trivespot is your go-to platform for connecting, learning, and thriving.
                                    </div>
                                    <div className='mt-3'>
                                        <button className='banner-content__center--button pt-lg-3 pt-2  pb-lg-3 pb-2 ps-3 pe-lg-5 pe-4' onClick={() => { navigate('/join') }}>Get Started <FaAngleRight /></button>
                                    </div>
                                </div>

                                {/* right hand images */}
                                <div className='col'>
                                        <img alt='banner-image' src={bannerImage} className='p-lg-5 banner-image'/>
                                </div>
                            </main>
                        </div>
                    </div>
                </div>
                <img src={bannerWave} alt='bannerWaveBase' className='banner-wave__base' />
            </header>
        </>
  )
}

export default Banner
